import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Advisory = () => (
  <Layout>
    <SEO title="Advisory Services" />
    <div style={{ maxWidth: `600px`, marginBottom: `1.45rem`, margin: `0 auto`}}>
        <div style={{marginBottom: `2.9rem`}}>
            <h3 style={{textAlign: `center`}}>
            Servizi di Advisory
            </h3>
            <p>
            Il Cabana ha accumulato negli anni una profonda conoscenza delle meccaniche di Bitcoin. Chiedeteci di:
            </p>
            <ul>
                <li>Custodia multi-sig con hardware wallet, manuali operativi e di sicurezza per ridurre al minimo i rischio di esfiltrazione o esposizione delle chiavi.</li>
                <li>Connessioni via API ai maggiori exchange, recupero dati di mercato, esecuzioni automatiche, aggiornamento valore posizioni, bilanciamento posizioni</li>
                <li>Materiali e documentazione sulle dinamiche di mercato di Bitcoin e la relazione con la situazione macroeconomica</li>
                <li>Applicazioni di automazione e machine learning applicate alle operatività di un fondo</li>
            </ul>
            <p>
            Questi servizi non sono disponibili per persone fisiche.
            </p>
        </div>
    </div>
  </Layout>
)

export default Advisory
